<script lang="ts" setup>
const { locale, locales, setLocale } = useI18n();

const route = <any>useRoute();

defineProps({
  color: {
    type: String,
    required: false,
  },
  icon: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const sites = ["NextPark.es", "NextPark.de", "NextPark.cz", "NextPark.pl", "NextPark.pt"];
</script>

<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props }">
      <v-btn :icon="icon" v-bind="props" :color="color ?? route.meta?.navbar_text" variant="text" :title="$t('components.locales.title')" rounded="3xl" :class="{'md:mr-4': true, 'hidden md:inline': icon }">
        <v-icon :start="!icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-hidden="true" role="presentation" focusable="false" style="display: block; height: 20px; width: 20px; fill: currentcolor"><path d="M8 .25a7.77 7.77 0 0 1 7.75 7.78 7.75 7.75 0 0 1-7.52 7.72h-.25A7.75 7.75 0 0 1 .25 8.24v-.25A7.75 7.75 0 0 1 8 .25zm1.95 8.5h-3.9c.15 2.9 1.17 5.34 1.88 5.5H8c.68 0 1.72-2.37 1.93-5.23zm4.26 0h-2.76c-.09 1.96-.53 3.78-1.18 5.08A6.26 6.26 0 0 0 14.17 9zm-9.67 0H1.8a6.26 6.26 0 0 0 3.94 5.08 12.59 12.59 0 0 1-1.16-4.7l-.03-.38zm1.2-6.58-.12.05a6.26 6.26 0 0 0-3.83 5.03h2.75c.09-1.83.48-3.54 1.06-4.81zm2.25-.42c-.7 0-1.78 2.51-1.94 5.5h3.9c-.15-2.9-1.18-5.34-1.89-5.5h-.07zm2.28.43.03.05a12.95 12.95 0 0 1 1.15 5.02h2.75a6.28 6.28 0 0 0-3.93-5.07z"></path></svg>
        </v-icon>
        <span v-if="!icon" class="uppercase font-semibold text-sm">
          {{ locale }}
        </span>
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card variant="elevated" class="p-4">
        <v-card-title> {{ $t("components.locales.title") }} </v-card-title>
        <v-card-text>
          <v-row class="text-center font-semibold">
            <v-col
              cols="6"
              v-for="lang in locales"
              :class="{ 'hover:bg-gray-tonal rounded-3xl cursor-pointer	': true, 'font-extrabold': lang.code === locale }"
              @click="
                setLocale(lang.code);
                isActive.value = false;
              "
            >
              <v-icon start>
                <img :src="`https://flagcdn.com/h20/` + lang.flag + `.png`" :alt="lang.name" class="h-4 w-4 rounded-full" />
              </v-icon>
              {{ lang.name }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="my-4" />

        <v-card-title> {{ $t("components.locales.region") }} </v-card-title>
        <v-card-text>
          <v-row class="text-center">
            <v-col cols="6" md="4" v-for="site in sites">
              <a :href="`https://` + site?.toLowerCase()" class="hover:underline">{{ site }}</a>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isActive.value = false">
            {{ $t("buttons.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
